// **********************
// Showcase Carousel
//***********************

/* Dynamic carousel which gets data from JSON */
const showcaseCarousel = new Vue({
    el:'#showcase-slides',
    data: {
        slides:[],
        apiUrl: ''
    },
    created: function() {

        this.apiUrl = window.location.origin + "/newadminapi/carousel/slides"
        if( window.location.origin.indexOf( "localdev") >= 0 ){
            this.apiUrl = "http://localhost:9000/carousel/slides/";
        }

        /* Get slides data from the user session if available */
        if (sessionStorage.slides){
            this.slides = JSON.parse(sessionStorage.getItem('slides'));
        }else{
            /* Gets slides data from JSON API */
            $.getJSON(this.apiUrl, function(data){
                showcaseCarousel.slides = data;

                /* Save session data */
                sessionStorage.setItem('slides', JSON.stringify(data));
            })
        }
    },
    mounted: function() {

        /* if the data is loaded initialize the slides */
        if(this.slides.length > 0){
            initSlides();
        }
    },
    updated: function() {
        /* Initializes the slides after component is updated */
        initSlides();
    }
})
